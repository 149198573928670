
import { defineComponent, reactive, ref, UnwrapRef, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import moment, { Moment } from 'moment'
import { notification } from 'ant-design-vue'

import quizService from '../../../services/quiz'
import validate from '../../../services/validator'

interface QuizFormState {
  _id?: string;
  name: string;
  description: string;
  start_time: string;
  end_time: string;
  no_of_attempt: number;
  review: boolean;
  shuffle: boolean;
  no_of_questions: number;
  time_limit: number;
  course: string;
  chapter: string;
  is_published: boolean;
}

export default defineComponent({
  props: ['showQuizModel', 'editQuiz', 'editQuizData'],
  setup (props, { emit }) {
    const route = useRoute()
    const quizFormRef = ref()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quizRules = validate.quizValidation
    const visible = ref<boolean>(false)
    const quizForm: UnwrapRef<QuizFormState> = reactive({
      name: '',
      description: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_time: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_time: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      no_of_attempt: 0,
      review: false,
      shuffle: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      no_of_questions: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      time_limit: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_published: false,
      chapter: '',
      course: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const createQuiz = async (values: QuizFormState) => {
      try {
        values.course = courseId.value
        values.chapter = chapterId.value
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.time_limit = +values.time_limit
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_attempt = +values.no_of_attempt
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_questions = +values.no_of_questions
        const responce = await quizService.createQuiz(values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          visible.value = false
          emit('refreshQuizList', responce)
          quizFormRef.value.resetFields()
          notify('Success', 'Chapter added successfully', 'success')
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const editQuiz = async (values: QuizFormState) => {
      try {
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.time_limit = +values.time_limit
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_attempt = +values.no_of_attempt
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_questions = +values.no_of_questions
        const responce = await quizService.updateQuiz(values, props.editQuizData._id)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          emit('refreshQuizList', { data: responce.data, index: props.editQuizData.index })
          notify('Success', 'Quiz updated successfully', 'success')
          visible.value = false
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const validateQuizForm = async () => {
      quizFormRef.value
        .validate()
        .then(() => {
          if (props.editQuiz) {
            editQuiz(quizForm)
          } else {
            createQuiz(quizForm)
          }
        })
        .catch((error: ValidateErrorEntity<QuizFormState>) => {
          console.log('error', error)
        })
    }
    const range = (start: number, end: number) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    const disabledDate = (current: Moment) => {
      return current && current < moment().endOf('day')
    }
    const disabledDateTime = () => {
      return {
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56]
      }
    }
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    watch(() => props.showQuizModel, () => {
      visible.value = true
      if (props.editQuiz) {
        quizForm.chapter = props.editQuizData.chapter
        quizForm.course = props.editQuizData.course
        quizForm.name = props.editQuizData.name
        quizForm.description = props.editQuizData.description
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.is_published = props.editQuizData.is_published
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.no_of_questions = props.editQuizData.no_of_questions
        quizForm.review = props.editQuizData.review
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.no_of_attempt = props.editQuizData.no_of_attempt
        quizForm.shuffle = props.editQuizData.shuffle
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.time_limit = props.editQuizData.time_limit
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.start_time = props.editQuizData.start_time
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.end_time = props.editQuizData.end_time
      }
    })
    onMounted(() => {
      console.log('yes')
    })
    return {
      courseId,
      chapterId,
      validateQuizForm,
      quizFormRef,
      quizRules,
      disabledDate,
      disabledDateTime,
      quizForm,
      moment,
      visible,
      handleCancel
    }
  }
})
