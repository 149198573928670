
import { defineComponent, ref, onMounted, createVNode, watch } from 'vue'
import { useRoute } from 'vue-router'
import { notification, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'

import AddEditQuiz from '@/components/agency/quiz/AddEditQuiz.vue'

import quizService from '../../../services/quiz'
import router from '../../../router'

export default defineComponent({
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    AddEditQuiz
  },
  props: ['quiz', 'refreshQuizListKey'],
  setup (props, { emit }) {
    const columns = [
      {
        title: 'Name',
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      // {
      //   title: 'Shuffle',
      //   dataIndex: 'shuffle',
      //   onFilter: (value, record) => record.shuffle.indexOf(value) === 0,
      //   sorter: (a, b) => a.shuffle.length - b.shuffle.length,
      //   sortDirections: ['descend', 'ascend'],
      //   slots: { customRender: 'shuffle' }
      // },
      // {
      //   title: 'No of Attempt',
      //   dataIndex: 'no_of_attempt'
      // },
      // {
      //   title: 'Q.per Page',
      //   dataIndex: 'no_of_questions'
      // },
      {
        title: 'Status',
        dataIndex: 'is_published',
        onFilter: (value, record) => record.is_published.indexOf(value) === 0,
        sorter: (a, b) => a.is_published.length - b.is_published.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'is_published' }
      },
      // {
      //   title: 'Time Limit',
      //   dataIndex: 'time_limit',
      //   onFilter: (value, record) => record.time_limit.indexOf(value) === 0,
      //   sorter: (a, b) => a.time_limit.length - b.time_limit.length,
      //   sortDirections: ['descend', 'ascend'],
      //   slots: { customRender: 'time_limit' }
      // },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    const route = useRoute()
    const showQuizModel = ref<number>(0)
    const showAddQuizModal = () => {
      showQuizModel.value++
    }
    const editQuiz = ref<boolean>(false)
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quizzes = ref<Array<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string }>>([])
    const editQuizData = ref<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string; index: number }>()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getCourseQuiz = async () => {
      try {
        const responce = await quizService.getCourseChapterQuiz(courseId.value, chapterId.value)
        quizzes.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const editQuizModel = async (quiz, index) => {
      editQuizData.value = quiz
      editQuizData.value.index = index
      editQuiz.value = true
      showQuizModel.value++
    }
    const changeQuizStatus = async (quiz, status) => {
      try {
        // eslint-disable-next-line @typescript-eslint/camelcase
        quiz.is_published = status
        await quizService.updateQuiz(quiz, quiz._id)
        notify('Success', (status ? 'Quiz published' : 'Quiz Unpublished'), 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const viewQuiz = async (quiz) => {
      router.push(`/course/${courseId.value}/chapter/${chapterId.value}/quiz/${quiz._id}`)
    }
    const deleteQuiz = async (quiz, index) => {
      try {
        await quizService.deleteQuiz(quiz._id)
        quizzes.value.splice(index, 1)
        emit('refreshQuizList')
        notify('Success', 'Quiz deleted successfully', 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const refreshQuiz = async (data) => {
      quizzes.value[data.index] = data.data
    }
    const showDeleteConfirm = async (quiz, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          deleteQuiz(quiz, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    watch(() => props.refreshQuizListKey, () => {
      quizzes.value.push(props.quiz)
    })
    onMounted(() => {
      getCourseQuiz()
    })
    return {
      courseId,
      chapterId,
      columns,
      quizzes,
      changeQuizStatus,
      viewQuiz,
      notify,
      showDeleteConfirm,
      showQuizModel,
      showAddQuizModal,
      refreshQuiz,
      editQuiz,
      editQuizData,
      editQuizModel
    }
  }
})
