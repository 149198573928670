<template>
  <a-modal title="Add New Quiz" :visible="visible" @ok="validateQuizForm" @cancel="handleCancel" width="600px">
    <div class="add-edit-quiz">
      <a-form
        ref="quizFormRef"
        :model="quizForm"
        :rules="quizRules"
        layout="vertical"
        :wrapper-col="{ span: 24 }"
        :label-col="{ span: 24 }"
      >
        <a-form-item label="Quiz name" required has-feedback name="name">
          <a-input v-model:value="quizForm.name" size="large" type="text" placeholder="Enter name" allow-clear autocomplete="off"/>
        </a-form-item>
        <!-- <a-form-item label="Description" has-feedback name="description">
          <a-textarea v-model:value="quizForm.description" />
        </a-form-item> -->
        <!--<a-form-item label="Start Date & Time" has-feedback name="start_time">
          <a-date-picker
            size="large"
            v-model:value="quizForm.start_time"
            format="YYYY-MM-DD HH:mm:ss"
            :disabled-date="disabledDate"
            :disabled-time="disabledDateTime"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            style="width:100%"
          />
        </a-form-item>
        <a-form-item label="End Date & Time" has-feedback name="end_time">
          <a-date-picker
            size="large"
            v-model:value="quizForm.end_time"
            format="YYYY-MM-DD HH:mm:ss"
            :disabled-date="disabledDate"
            :disabled-time="disabledDateTime"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            style="width:100%"
          />
        </a-form-item>
        <a-form-item label="Questions per page" has-feedback name="no_of_questions">
          <a-select v-model:value="quizForm.no_of_questions" size="large" placeholder="Select no of questions">
            <a-select-option :value="null">Select One</a-select-option>
            <a-select-option value="5">5</a-select-option>
            <a-select-option value="10">10</a-select-option>
            <a-select-option value="15">15</a-select-option>
            <a-select-option value="20">20</a-select-option>
            <a-select-option value="25">25</a-select-option>
            <a-select-option value="30">30</a-select-option>
            <a-select-option value="35">35</a-select-option>
            <a-select-option value="40">40</a-select-option>
            <a-select-option value="45">45</a-select-option>
            <a-select-option value="50">50</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="No of attempt" has-feedback name="no_of_attempt">
          <a-select v-model:value="quizForm.no_of_attempt" size="large" placeholder="Select attempt">
            <a-select-option :value="null">Select One</a-select-option>
            <a-select-option value="0">0</a-select-option>
            <a-select-option value="1">1</a-select-option>
            <a-select-option value="2">2</a-select-option>
            <a-select-option value="3">3</a-select-option>
            <a-select-option value="4">4</a-select-option>
            <a-select-option value="5">5</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Attempt Review">
          <a-switch v-model:checked="quizForm.review" />
        </a-form-item>
        <a-form-item label="Time Limit" has-feedback name="time_limit">
          <a-select v-model:value="quizForm.time_limit" size="large" placeholder="Select time limit">
            <a-select-option :value="null">Select One</a-select-option>
            <a-select-option value="5">5 Minutes</a-select-option>
            <a-select-option value="10">10 Minutes</a-select-option>
            <a-select-option value="15">15 Minutes</a-select-option>
            <a-select-option value="20">20 Minutes</a-select-option>
            <a-select-option value="25">25 Minutes</a-select-option>
            <a-select-option value="30">30 Minutes</a-select-option>
            <a-select-option value="35">35 Minutes</a-select-option>
            <a-select-option value="40">40 Minutes</a-select-option>
            <a-select-option value="45">45 Minutes</a-select-option>
            <a-select-option value="50">50 Minutes</a-select-option>
            <a-select-option value="55">55 Minutes</a-select-option>
            <a-select-option value="60">60 Minutes</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Shuffle Question" has-feedback name="shuffle">
          <a-switch v-model:checked="quizForm.shuffle" />
        </a-form-item>-->
      </a-form>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, UnwrapRef, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import moment, { Moment } from 'moment'
import { notification } from 'ant-design-vue'

import quizService from '../../../services/quiz'
import validate from '../../../services/validator'

interface QuizFormState {
  _id?: string;
  name: string;
  description: string;
  start_time: string;
  end_time: string;
  no_of_attempt: number;
  review: boolean;
  shuffle: boolean;
  no_of_questions: number;
  time_limit: number;
  course: string;
  chapter: string;
  is_published: boolean;
}

export default defineComponent({
  props: ['showQuizModel', 'editQuiz', 'editQuizData'],
  setup (props, { emit }) {
    const route = useRoute()
    const quizFormRef = ref()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quizRules = validate.quizValidation
    const visible = ref<boolean>(false)
    const quizForm: UnwrapRef<QuizFormState> = reactive({
      name: '',
      description: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_time: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_time: '',
      // eslint-disable-next-line @typescript-eslint/camelcase
      no_of_attempt: 0,
      review: false,
      shuffle: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      no_of_questions: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      time_limit: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_published: false,
      chapter: '',
      course: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const createQuiz = async (values: QuizFormState) => {
      try {
        values.course = courseId.value
        values.chapter = chapterId.value
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.time_limit = +values.time_limit
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_attempt = +values.no_of_attempt
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_questions = +values.no_of_questions
        const responce = await quizService.createQuiz(values)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          visible.value = false
          emit('refreshQuizList', responce)
          quizFormRef.value.resetFields()
          notify('Success', 'Chapter added successfully', 'success')
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const editQuiz = async (values: QuizFormState) => {
      try {
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.time_limit = +values.time_limit
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_attempt = +values.no_of_attempt
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.no_of_questions = +values.no_of_questions
        const responce = await quizService.updateQuiz(values, props.editQuizData._id)
        if (typeof responce.data === 'string') {
          notify('Error', responce.data, 'error')
        } else {
          emit('refreshQuizList', { data: responce.data, index: props.editQuizData.index })
          notify('Success', 'Quiz updated successfully', 'success')
          visible.value = false
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const validateQuizForm = async () => {
      quizFormRef.value
        .validate()
        .then(() => {
          if (props.editQuiz) {
            editQuiz(quizForm)
          } else {
            createQuiz(quizForm)
          }
        })
        .catch((error: ValidateErrorEntity<QuizFormState>) => {
          console.log('error', error)
        })
    }
    const range = (start: number, end: number) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    const disabledDate = (current: Moment) => {
      return current && current < moment().endOf('day')
    }
    const disabledDateTime = () => {
      return {
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56]
      }
    }
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    watch(() => props.showQuizModel, () => {
      visible.value = true
      if (props.editQuiz) {
        quizForm.chapter = props.editQuizData.chapter
        quizForm.course = props.editQuizData.course
        quizForm.name = props.editQuizData.name
        quizForm.description = props.editQuizData.description
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.is_published = props.editQuizData.is_published
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.no_of_questions = props.editQuizData.no_of_questions
        quizForm.review = props.editQuizData.review
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.no_of_attempt = props.editQuizData.no_of_attempt
        quizForm.shuffle = props.editQuizData.shuffle
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.time_limit = props.editQuizData.time_limit
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.start_time = props.editQuizData.start_time
        // eslint-disable-next-line @typescript-eslint/camelcase
        quizForm.end_time = props.editQuizData.end_time
      }
    })
    onMounted(() => {
      console.log('yes')
    })
    return {
      courseId,
      chapterId,
      validateQuizForm,
      quizFormRef,
      quizRules,
      disabledDate,
      disabledDateTime,
      quizForm,
      moment,
      visible,
      handleCancel
    }
  }
})
</script>
<style lang="scss">
.add-edit-quiz {
}

</style>
