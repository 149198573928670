
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { notification } from 'ant-design-vue'

import ViewChapter from '@/components/agency/chapter/ViewChapter.vue'
import AddEditQuiz from '@/components/agency/quiz/AddEditQuiz.vue'
import ListQuiz from '@/components/agency/quiz/ListQuiz.vue'

import chapterService from '../../../services/chapter'
import quizService from '../../../services/quiz'

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    ViewChapter,
    AddEditQuiz,
    ArrowLeftOutlined,
    ListQuiz
  },
  setup () {
    const route = useRoute()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quiz = ref<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string }>()
    const activeTab = ref<number>(1)
    const showQuizModel = ref<number>(0)
    const chapter = ref()
    const quizzes = ref()
    const disableAddQuiz = ref<boolean>(false)
    const showAddQuizModal = () => {
      showQuizModel.value++
    }
    const refreshQuizListKey = ref<number>(0)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getCourseQuiz = async () => {
      try {
        const responce = await quizService.getCourseChapterQuiz(courseId.value, chapterId.value)
        quizzes.value = responce.data
        if (responce.data.length >= 1) {
          disableAddQuiz.value = true
        } else {
          disableAddQuiz.value = false
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const refreshQuiz = async (data) => {
      if (data) {
        quiz.value = data.data
        refreshQuizListKey.value++
      }
      getCourseQuiz()
    }
    const getChapterDetails = async () => {
      try {
        const responce = await chapterService.getChapterDetails(chapterId.value)
        chapter.value = responce.data
      } catch (error) {

      }
    }
    onMounted(() => {
      getChapterDetails()
      getCourseQuiz()
    })
    return {
      courseId,
      chapterId,
      activeTab,
      showQuizModel,
      showAddQuizModal,
      refreshQuizListKey,
      refreshQuiz,
      quiz,
      chapter,
      quizzes,
      disableAddQuiz
    }
  }
})
