
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import chapterService from '../../../services/chapter'

export default defineComponent({
  setup () {
    const route = useRoute()
    const chapter = ref<{ _id: string; title: string; content: string; order: number; course: string }>()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const getChapter = async (id) => {
      const responce = await chapterService.getChapterDetails(id)
      chapter.value = responce.data
    }
    onMounted(() => {
      getChapter(chapterId.value)
    })
    return {
      chapter,
      courseId,
      chapterId,
      getChapter
    }
  }
})
